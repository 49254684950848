import Dropzone from 'dropzone';
import I18n from 'libs/I18n';
import {InitFlashMessages, setFlash} from 'utils/FlashMessages';

((w) => {
  w.Dropzone = Dropzone;
  w.I18n = I18n;
  w.InitFlashMessages = InitFlashMessages;
  w.setFlash = setFlash;
})(window);
