import React, {
  useState, useEffect, useCallback, useRef
} from 'react';
import ReactDOM from 'react-dom';
import Alert from 'react-bootstrap/Alert';
import {uniqueId} from 'lodash';
import Cookies from 'universal-cookie';

// Observer function definition
let observer = null;

// Observer instance message emitter
const emitChange = (props = {}) => observer(props);

// global JS message receiver
const setFlash = (props) => emitChange(props);

// Observer function initialization
const observe = (o) => {
  observer = o;
  emitChange();
};

// ERB Initializer, creates observer function callback
const InitFlashMessages = () => {
  observe((props) => {
    ReactDOM.render(<FlashMessages {...props} />, document.getElementById('flash-message'));
  });
};

// Unescape flash message cookies
const unescapeHTMLCookie = (str) => {
  const eList = {'#39': "'", amp: '&'};
  return str.replace(/\+/g, ' ').replace(/&([^ ;]+);/g, (e, eCode) => eList[eCode] || e);
};

function redirectWithFlash(props) {
  const {path, type, message} = props;
  const cookies = new Cookies();
  cookies.set('flash', [[type, message]], {path: '/'});
  window.location.href = path;
}

const getMessagesFromCookies = (flashCookies) => {
  let type = null;
  let messages = [];
  if(!flashCookies) return {type, messages};

  try {
    messages = flashCookies.map(([, msg]) => unescapeHTMLCookie(msg));
    [[type]] = flashCookies;
  } catch (error) {
    console.error('Failed to read flash cookie');
  }
  return {type, messages};
};

const convertIcon = (type) => {
  switch(type) {
    case 'error':
      return 'close-octagon';
    case 'warning':
      return 'alert-circle';
    case 'success':
      return 'check-circle-outline';
    default:
      return 'information-outline';
  }
};

// eslint-disable-next-line max-lines-per-function
function FlashMessages(props) {
  const [flashMessage, setFlashMessage] = useState({type: null, messages: []});
  const timer = null;
  const timerRef = useRef(timer);

  const startTimer = useCallback((ms = 3000) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if(timerRef.current) clearTimeout(timerRef.current);
      setFlashMessage(() => ({type: null, messages: []}));
    }, ms);
  }, [timerRef]);

  // Reset Timer on messages change
  useEffect(() => {
    if(flashMessage.messages.length) startTimer();
  }, [flashMessage, startTimer]);

  // Set new messages on prop changes (when called from setFlash)
  useEffect(() => {
    const {message, messageList, type} = props;
    const messages = message ? [message] : messageList;
    if(!messages) return;

    setFlashMessage(() => ({type, messages}));
  }, [props]);

  // Load Flash from cookies onMount
  useEffect(() => {
    const cookies = new Cookies();
    setFlashMessage(getMessagesFromCookies(cookies.get('flash')));
    cookies.remove('flash', {path: '/'});
  }, []);

  if(flashMessage.messages.length === 0) return '';

  return (
    <Alert
      variant={flashMessage.type === 'error' ? 'danger' : flashMessage.type}
      onMouseEnter={() => clearTimeout(timerRef.current)}
      onMouseLeave={() => startTimer(2000)}
      onClose={() => setFlashMessage({type: null, messages: []})}
      dismissible
    >
      <div className="d-flex">
        <i className={`mdi mdi-${convertIcon(flashMessage.type)} mr-3 text-${flashMessage.type}`} />
        {flashMessage.messages.length === 1 ? flashMessage.messages.map((m) => (m)) : (
          <div>
            <p>
              {flashMessage.type === 'error'
                ? window.I18n.t('admin.commons.errors.list', {errors: flashMessage.messages.length})
                : window.I18n.t('admin.commons.messages_list', {messages: flashMessage.messages.length})}
            </p>
            <ul className="pl-3">
              {flashMessage.messages.map((m) => (<li key={uniqueId('message')}>{m}</li>))}
            </ul>
          </div>
        )}
      </div>
    </Alert>
  );
}

export {InitFlashMessages, setFlash, redirectWithFlash};
